import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { Field } from "formik"
import { FormikTextField as TextField } from "mill/components/TextField"
import { FormikCheckbox as Checkbox } from "shared/components/Checkbox"
import { disabledBackground } from "mill/utils/colors"
import useNotifications from "shared/hooks/useNotifications"
import useConfirmationModal from "shared/hooks/useConfirmationModal"
import { md } from "mill/utils/breakpoints"

import DELETE_WEBHOOK_SUBSCRIPTION from "mill/graphql/DeleteWebhookSubscription"

const Fieldset = styled.fieldset`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 4px solid ${disabledBackground};
  padding: 2rem;

  legend {
    padding: 1rem;
    font-size: 1.8rem;
  }

  div:first-of-type,
  div:nth-of-type(2) {
    margin-top: 0;
  }

  @media ${md} {
    > div {
      display: inline-block;
      width: 50%;
    }
  }
`

const DeleteText = styled.p`
  span {
    color: ${props => props.theme.colors.error};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`

const WebhookForm = props => {
  let navigate = useNavigate()
  const { displayNotification } = useNotifications()
  const { showConfirmationModal } = useConfirmationModal()

  const [deleteWehookSubscription] = useMutation(DELETE_WEBHOOK_SUBSCRIPTION, {
    onError: error => {
      const message = error.message.replace("GraphQL error:", "").trim()
      displayNotification({
        text: message
      })
    },
    onCompleted: () => {
      displayNotification({
        text: "Webhook deleted",
        style: "success"
      })
      navigate(`/admin/account/webhooks`)
    }
  })

  const handleDeleteWebhookSubscription = () => {
    showConfirmationModal({
      title: `Are you sure you want to delete this wehook?`,
      text: "You will no longer be able to view and edit this webhook.",
      yesText: "Yes, delete this webhook",
      yesAction: () => {
        deleteWehookSubscription({
          variables: {
            webhookSubscriptionId: props.webhookId
          }
        })
      }
    })
  }

  return (
    <React.Fragment>
      <Field
        withFieldset
        fullWidth
        name="url"
        type="text"
        data-qa="url"
        component={TextField}
        label="Request URL"
        placeholder="https://"
      />
      <Field
        withFieldset
        fullWidth
        name="secret"
        type="text"
        data-qa="secret"
        component={TextField}
        label="Secret"
        style={{ marginBottom: "2rem" }}
      />
      <Field
        withFieldset
        inline
        name="active"
        data-qa="active"
        type="checkbox"
        component={Checkbox}
        label="Webhook active?"
      />
      {props.webhookId && (
        <DeleteText>
          Alternatively,{" "}
          <span onClick={handleDeleteWebhookSubscription}>delete</span> this
          webhook
        </DeleteText>
      )}

      <Fieldset>
        <legend>Events</legend>
        <Field
          withFieldset
          inline
          name="learnerAddedToYarno"
          data-qa="learner-added-to-yarno"
          type="checkbox"
          component={Checkbox}
          label="Learner added"
          description={`A new learner is added to ${window.PLATFORM_NAME}`}
        />
        <Field
          withFieldset
          inline
          name="learnerAddedToCampaign"
          data-qa="learner-added-to-campaign"
          type="checkbox"
          component={Checkbox}
          label="Learner added to campaign"
          description={`A learner is added to a ${window.PLATFORM_NAME} campaign`}
        />
        <Field
          withFieldset
          inline
          name="learnerAddedToTeam"
          data-qa="learner-added-to-team"
          type="checkbox"
          component={Checkbox}
          label="Learner added to team"
          description={`A learner is added to a ${window.PLATFORM_NAME} team`}
        />
        <Field
          withFieldset
          inline
          name="learnerVerified"
          data-qa="learner-verified"
          type="checkbox"
          component={Checkbox}
          label="Learner verified"
          description="A learner has verified their email and set their password"
        />
        <Field
          withFieldset
          inline
          name="learnerCompletedOnboarding"
          data-qa="learner-completed-onboarding"
          type="checkbox"
          component={Checkbox}
          label="Learner completed onboarding"
          description={`A learner completes or skips the ${window.PLATFORM_NAME} onboarding tour`}
        />
        <Field
          withFieldset
          inline
          name="learnerAnsweredQuestion"
          data-qa="learner-answered-question"
          type="checkbox"
          component={Checkbox}
          label="Learner answered a question"
          description="A learner answers a standard or primer question"
        />
        <Field
          withFieldset
          inline
          name="learnerCompletedCampaign"
          data-qa="learner-completed-campaign"
          type="checkbox"
          component={Checkbox}
          label="Learner completed a campaign"
          description="A learner completes a campaign"
        />
        <Field
          withFieldset
          inline
          name="learnerRemovedFromCampaign"
          data-qa="learner-removed-from-campaign"
          type="checkbox"
          component={Checkbox}
          label="Learner removed from a campaign"
          description="A learner is retired from a campaign"
        />
        <Field
          withFieldset
          inline
          name="learnerRemovedFromTeam"
          data-qa="learner-removed-from-team"
          type="checkbox"
          component={Checkbox}
          label="Learner removed from a team"
          description="A learner is retired from a team"
        />
        <Field
          withFieldset
          inline
          name="learnerProvidedFeedback"
          data-qa="learner-provided-feedback"
          type="checkbox"
          component={Checkbox}
          label="Learner provided feedback"
          description="A learner provides platform, campaign or question feedback"
        />
        <Field
          withFieldset
          inline
          name="learnerUpdatedProfile"
          data-qa="learner-updated-profile"
          type="checkbox"
          component={Checkbox}
          label="Learner updated profile"
          description="A learner updates their name or email"
        />
        <Field
          withFieldset
          inline
          name="campaignCreated"
          data-qa="campaign-created"
          type="checkbox"
          component={Checkbox}
          label="Campaign created"
          description="A new campaign has been created"
        />
        <Field
          withFieldset
          inline
          name="campaignWarmingUp"
          data-qa="campaign-warming-up"
          type="checkbox"
          component={Checkbox}
          label="Campaign in warmup"
          description="A campaign is in warm up and pre-primers are sent to learners"
        />
        <Field
          withFieldset
          inline
          name="campaignLaunched"
          data-qa="campaign-launched"
          type="checkbox"
          component={Checkbox}
          label="Campaign launched"
          description="Campaign is active and learners can answer questions"
        />
        <Field
          withFieldset
          inline
          name="campaignCoolingDown"
          data-qa="campaign-cooling-down"
          type="checkbox"
          component={Checkbox}
          label="Campaign in cooldown"
          description="Campaign is no longer sending new campaign questions and post-primers are sent"
        />
        <Field
          withFieldset
          inline
          name="campaignCompleted"
          data-qa="campaign-completed"
          type="checkbox"
          component={Checkbox}
          label="Campaign completed"
          description="Campaign is completed and questions can no longer be answered"
        />
      </Fieldset>
    </React.Fragment>
  )
}

export default WebhookForm
